<template>

  <validation-observer
    ref="simpleRules"
    class="p-3"
  >
    <b-form :ref="formidentifier">
      <h4
        v-if="ismodal != true"
        class="ml-2"
      >
        &nbsp;{{ ($route.params.id != null) ? 'Edit '+title : 'Tambah '+title }}
      </h4>
      <b-row
        class="ml-1 mr-1"
      >
        
        <b-col
          v-for="(field, index) in fields"
          :key="index"
          :cols="rowcol"
        >
          <!-- with helper text -->
          <b-form-group
            :label-cols-md="labelcol"
            :label="field.label"
            :label-for="field.key"
            :description="field.desc"
            style="font-size:12px"
          >
            <validation-provider
              #default="{ errors }"
              :name="field.label"
              :rules="field.rules"
            >
              <b-form-input
                v-if="field.type == 'input'"
                :id="field.key"
                v-model="model[field.key]"
                :plaintext="false"
                :disabled="field.disabled == '' || field.disabled == false || field.disabled == null ? false : true"
                :placeholder="field.placeholder"
                :state="errors.length > 0 ? false:null"
              />
              <b-form-select
                v-else-if="field.type == 'select'"
                v-model="model[field.key]"
                :options="field.options"
                value-field="id"
                text-field="name"
              />
              <form-v-select
                v-else-if="field.type == 'select2'"
                v-model="model[field.key]"
                :selected="model[field.key]"
                :dataurl="field.dataurl"
                placeholder="Select Value"
              />
              <form-autocomplate
                v-else-if="field.type == 'autocomplate'"
                :id="field.key"
                :value-key="field.key"
                :value="model[field.key]"
                :placeholder="field.placeholder"
                :state="errors.length > 0 ? false:null"
                :index-value="field.indexValue"
                @change="changeAutocomplate"
                @input="changeInput"
              />
              <baseSelect
                v-else-if="field.type == 'select-ajax'"
                :id="field.key"
                v-model="model[field.key]"
                :dataurl="field.dataurl"
                :title="field.label"
                :datafields="field.dataFields"
                :datavalue="model[field.data_value]"
              />
              <baseSelectSingle
                v-else-if="field.type == 'select-single'"
                :id="field.key"
                v-model="model[field.key]"
                :dataurl="field.dataurl"
                :title="field.label"
              />
              <baseSelectMulti
                v-else-if="field.type == 'select-multi'"
                :id="field.key"
                v-model="model[field.key]"
                :dataurl="field.dataurl"
                :title="field.title"
                :datavalue="model[field.data_value]"
              />
              <cleave
                v-else-if="field.type == 'number'"
                :id="field.key"
                v-model="model[field.key]"
                class="form-control"
                :raw="true"
                :options="options.number"
                :placeholder="field.placeholder"
              />
              <b-form-textarea
                v-else-if="field.type == 'text'"
                :id="field.key"
                v-model="model[field.key]"
                :placeholder="field.placeholder"
                rows="3"
              />
              <div 
                v-else-if="field.type == 'radio'"
                class="row pl-1"
              >
                <b-form-radio
                  v-model="model[field.key]"
                  :value="field.setting.sub_a.value"
                  class="mr-1 mb-1"
                >
                  {{ field.setting.sub_a.label }}
                </b-form-radio>
                <b-form-radio
                  v-model="model[field.key]"
                  :value="field.setting.sub_b.value"
                  class="mr-1 mb-1"
                >
                  {{ field.setting.sub_b.label }}
                </b-form-radio>
                <b-form-radio
                  v-if="field.setting.sub_c"
                  v-model="model[field.key]"
                  :value="field.setting.sub_c.value"
                  class="mr-1 mb-1"
                >
                  {{ field.setting.sub_c.label }}
                </b-form-radio>
                <b-form-radio
                  v-if="field.setting.sub_d"
                  v-model="model[field.key]"
                  :value="field.setting.sub_d.value"
                  class="mr-1 mb-1"
                >
                  {{ field.setting.sub_d.label }}
                </b-form-radio>
                <b-form-radio
                  v-if="field.setting.sub_e"
                  v-model="model[field.key]"
                  :value="field.setting.sub_e.value"
                  class="mr-1 mb-1"
                >
                  {{ field.setting.sub_e.label }}
                </b-form-radio>
                <b-form-radio
                  v-if="field.setting.sub_f"
                  v-model="model[field.key]"
                  :value="field.setting.sub_f.value"
                  class="mr-1 mb-1"
                >
                  {{ field.setting.sub_f.label }}
                </b-form-radio>
                <b-form-radio
                  v-if="field.setting.sub_g"
                  v-model="model[field.key]"
                  :value="field.setting.sub_g.value"
                  class="mr-1 mb-1"
                >
                  {{ field.setting.sub_g.label }}
                </b-form-radio>
                <b-form-radio
                  v-if="field.setting.sub_h"
                  v-model="model[field.key]"
                  :value="field.setting.sub_h.value"
                  class="mr-1 mb-1"
                >
                  {{ field.setting.sub_h.label }}
                </b-form-radio>
                <b-form-radio
                  v-if="field.setting.sub_i"
                  v-model="model[field.key]"
                  :value="field.setting.sub_i.value"
                  class="mr-1 mb-1"
                >
                  {{ field.setting.sub_i.label }}
                </b-form-radio>
              </div>
              <b-form-checkbox
                v-else-if="field.type == 'bool'"
                v-model="model[field.key]"
              >
                {{ field.lbel }}
              </b-form-checkbox>
              <flat-pickr
                v-else-if="field.type == 'date'"
                v-model="model[field.key]"
                class="form-control"
                :placeholder="field.placeholder"
              />
              <basemap
                v-else-if="field.type == 'map'"
                :latitude="lat"
                :longitude="lng"
                :sync="field.ref"
                @updateMap="updateMap"
              />
              <form-file
                v-else-if="field.type == 'file'"
                v-model="model[field.key]"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <slot name="buttonaddon" />
          <div v-if="btnForm">
            <b-button
              class="mr-1"
              size="md"
              variant="primary"
              type="submit"
              :disabled="loading"
              @click.prevent="validationForm"
            >
            
              <span v-if="loading">
                <b-spinner small />
                Loading...
              </span>
              <span v-else>Simpan</span>
            </b-button>
            <b-button
              class="mr-1"
              size="md"
              variant="outline-secondary"
              type="button"
              @click.prevent="cancel"
            >
              <span v-if="ismodal">Batal</span>
              <span v-else>Batal</span>
            </b-button>
          </div>
          
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {BFormInput, BForm,BRow, BCol, BFormGroup,BButton,BFormSelect,BFormTextarea,BFormCheckbox,BSpinner,BFormRadio} from 'bootstrap-vue'
import { required, email } from '@validations'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FormAutocomplate from '@/views/base/form/FormAutocomplate.vue'
import Cleave from 'vue-cleave-component'
import flatPickr from 'vue-flatpickr-component'
import baseSelect from '@/views/base/BaseSelect.vue'
import baseSelectMulti from '@/views/base/BaseSelectMulti.vue'
import baseSelectSingle from '@/views/base/BaseSelectSingleData.vue'
import basemap from '@/views/base/BaseMap.vue'
import FormFile from './form/FormFile.vue'


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormRadio,
    BRow,
    BCol,
    BForm,
    BButton,
    BFormSelect,
    Cleave,
    BFormTextarea,
    BFormCheckbox,
    BSpinner,
    flatPickr,
    FormVSelect,
    baseSelect,
    baseSelectMulti,
    baseSelectSingle,
    basemap,
    FormFile,
    FormAutocomplate
  },
  props: {
    title_form: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    set_redirect: {
      type: Boolean,
      default: false,
    },
    cek_modal: {
      type: String,
      default: '',
    },
    btnForm: {
      type: Boolean,
      default: true
    },
    btnCustom: {
      type: Boolean,
      default: false
    },
    ismodal: {
      type: Boolean,
      default: false
    },
    dataitem: {
      type: Object,
      default: function () {
        return {}
      }
    },
    formidentifier: {
      type: String,
      default: 'baseform',
    },
    rowcol: {
      type: Number,
      default: 12,
    },
    labelcol: {
      type: Number,
      // set 0 to vertical
      default: 4,
    },
    baseroute: {
      type: String,
      default: '',
    },
    posturl: {
      type: String,
      default: '',
    },
    fields: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      loading :false,
      model:{address:''},
      lat:-7.24917,
      lng:112.75083,
      // validation
      required,
      email,
      options: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      dataForm:null,
      params:null
    }
  },
  beforeMount(){
    if(this.$route.params.id != null)
      this.$http.get(this.posturl+'/'+this.$route.params.id).then(res=>{
        this.model = res.data
        if(res.data.latitude){
            this.lat = parseFloat(res.data.latitude)
            this.model.latitude = parseFloat(res.data.latitude)
          }else{
            this.model.latitude = -7.24917
            this.lat = -7.24917
          }
          if(res.data.longitude){
            this.lng = parseFloat(res.data.longitude)
            this.model.longitude = parseFloat(res.data.longitude)
          }else{
            this.model.longitude = 112.75083
            this.lng = 112.75083
          }
      })
  },
  mounted(){
    if(Object.keys(this.dataitem).length != 0){
      this.model = Object.assign({},this.dataitem)

   //   console.log(this.baseroute)
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true
          this.filterDataSubmit()
          let paramData = this.params
          if(this.cek_modal == 'edit'){
            this.$http.put(this.posturl+'/'+this.model['id'],paramData)
            .then(()=>{
              this.successSubmit()
            })
            .catch(err=>{
              this.errorSubmit(err)
            })
            .finally(() => {this.loading = false})
          }else if(this.cek_modal == 'tambah'){
            this.$http.post(this.posturl,paramData)
            .then(()=>{
              this.successSubmit()
            })
            .catch(err=>{
              this.errorSubmit(err)
            })
            .finally(() => {this.loading = false})
          }else{
            if(this.$route.params.id == null){
              this.$http.post(this.posturl,paramData)
              .then(res=>{
                if(this.btnCustom != null){
                  this.dataForm = res.data.data.id
                }
                this.successSubmit()
              })
              .catch(err=>{
                this.errorSubmit(err)
              })
              .finally(() => {this.loading = false})       
            }else{
              this.$http.put(this.posturl+'/'+this.model['id'],paramData)
              .then(res=>{
                if(this.btnCustom != null){
                  this.dataForm = res.data.data.id
                }
                this.successSubmit()
              })
              .catch(err=>{
                this.errorSubmit(err)
              })
              .finally(() => {this.loading = false})
            }
          }
        }else{
          this.$bvToast.toast('Please correct form input before submit', {
            title: 'Form Error',
            solid: true,
            variant:'danger'
          })
        }
      })
    },

    async filterDataSubmit() {
      let newData ={}
      let isUpload = false
      let fileUpload = null
      for (let index = 0; index < this.fields.length; index++) {
        let row = this.fields[index]
        if(row.type == 'map'){
          newData['latitude'] = this.model['latitude'].toString()
          newData['longitude'] = this.model['longitude'].toString()
        }else if(row.type == 'file') {
          isUpload = true
          fileUpload = this.model[row.key]
          continue
        }else if(row.type == 'details'){
          newData[row.key] = this.dataDetail
          continue
        }else if(row.key == 'activity_type'){
          continue
        }else if(row.key == 'hr'){
          continue
        }else if(this.model[row.key] == null) {
          row.type == 'bool'?newData[row.key] = false:row.type == 'number'? newData[row.key] = 0 : newData[row.key] = ''
          continue
        }else if(typeof this.model[row.key] === 'object'){
          if(Array.isArray(this.model[row.key])){
            let arrData = this.model[row.key].map(e => e.id)
            newData[row.key] = arrData

          }else{
            newData[row.key+'_id'] = this.model[row.key].id
          }
        }else {
          newData[row.key] = this.model[row.key]
        }
      }

      if(isUpload){
        let formData = new FormData()
      //  console.log(fileUpload)
        formData.append('file', fileUpload)
        let res = await this.$http.post('upload/file',formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })


     //   console.log(res)        
      }
      this.params = newData
    },

    successSubmit(){
      this.loading = false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Submit Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      if(this.set_redirect){
        this.$router.go(-1)
      }else{
        if(this.dataForm != null){
          this.$router.push({ name:this.baseroute+'-detail', params : { id:this.dataForm } })
        }else{
          if(this.ismodal){
          this.$emit("onsubmite", true)
          }else{
            this.$router.push({ name:this.$route.meta.pageActive })
          }
        }
      }
    },
    errorSubmit(err){

      // console.log(err)
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
    cancel(){
      if(this.ismodal)
        this.$emit("onsubmite", false)
      else
        this.$router.go(-1)
    },
    async updateMap(latlong){
      this.lat = parseFloat(latlong.lat)
      this.lng = parseFloat(latlong.lng)
      this.model['latitude'] = latlong.lat
      this.model['longitude'] = latlong.lng
      let { data } = await this.$http.get('https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat='+latlong.lat+'&lon='+latlong.lng)
      if(latlong.ref != "")
        this.model[latlong.ref] = data.display_name
    },
    changeAutocomplate(val){
      this.model[val.valueKey] = val.display_name
      this.lat = parseFloat(val.lat)
      this.lng = parseFloat(val.lon)
      this.model['latitude'] = val.lat
      this.model['longitude'] = val.lon
    },
    changeInput(val){
      this.model[val.valueKey] = val.value
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
