<template>

  <!-- Table Container Card -->
  <b-card
    no-body
    class="mb-0"
  >
    <div>
      <!-- Table Top -->
      <slot name="buttonaddon" />
      <h4 v-if="title != ''">
        {{ title }}
      </h4>
      <div v-if="custom_filter == true">
        <br><br><hr>
      </div>
      <b-row>
        <!-- Per Page -->
        <b-col
          v-if="page_size_hide != true"
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="pageSize"
            :options="perPage"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <label>entries</label>
          
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-if="search_hide != true"
              v-model="search"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
            <template>
              <slot name="buttonaddon2" />
              <b-button
                v-if="tombol_hide != true"
                variant="primary"
                @click="addForm()"
              >
                <span class="text-nowrap">
                  <feather-icon
                    icon="PlusIcon"
                  />
                  Tambah
                </span>
              </b-button>
            </template>
          </div>
        </b-col>
      </b-row>
      <br v-if="no_margin != true">
    </div>
    <b-overlay
      :show="loading"
      variant="light"
      :opacity="0.5"
      rounded="sm"
    >
      <b-table
        ref="refUserListTable"
        :items="items"
        responsive
        :fields="fieldsTable"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="sortDirection"
        class="position-relative table-bordered table-sm table mb-1"
      >
        <template
          v-if="baseroute == 'dokumen'"
          #cell(type)="data"
        >
          <span>{{ data.item.type == 'vehicle' ? 'Dokumen Kendaraan' : 'Dokumen SPB' }}</span>
        </template>

        <template
          v-if="baseroute == 'dokumen'"
          #cell(is_required)="data"
        >
          <center><span class="badge rounded-pill bg-info">{{ data.item.is_required == true ? 'Wajib' : 'Tidak Wajib' }}</span></center>
        </template>

        <template
          v-if="baseroute == 'jabatan'"
          #cell(level)="data"
        >
          <span v-if="data.item.level == 'ulp'">
            ULP
          </span>
          <span v-else-if="data.item.level == 'pusat'">
            Pusat
          </span>
          <span v-else-if="data.item.level == 'region'">
            Region
          </span>
          <span v-else-if="data.item.level == 'area'">
            Area Layanan
          </span>
          <span v-else>
            Project
          </span>
        </template>

        <template
          v-if="custom_action"
          #cell(action)="data"
        >
          <!-- <span
            v-if="isView"
            class="mr-1"
          >
            <b-link @click="viewForm(data)">
              <b-badge variant="success">
                <feather-icon icon="FileIcon" />
              </b-badge>
            </b-link>
          </span> -->
          <span
            v-if="isDetail"
            class="mr-1"
          >
            <!-- <b-link @click="editForm(data)">
              <b-badge variant="primary" size="md">
                <feather-icon icon="EditIcon" />
              </b-badge>
            </b-link> -->
            <b-button
              variant="primary"
              size="sm"
              class="mr-0 btn-icon"
              @click="detail(data)"
            >
              <feather-icon
                icon="FolderIcon"
              />
            </b-button>
          </span>  
          <span
            v-if="isEdit"
            class="mr-1"
          >
            <!-- <b-link @click="editForm(data)">
              <b-badge variant="primary" size="md">
                <feather-icon icon="EditIcon" />
              </b-badge>
            </b-link> -->
            <b-button
              variant="primary"
              size="sm"
              class="mr-0 btn-icon"
              @click="editForm(data)"
            >
              <feather-icon
                icon="EditIcon"
              />
            </b-button>
          </span>
          <span
            v-if="isDelete"
            class="mr-1"
          >
            <b-button
              variant="warning"
              size="sm"
              class="mr-0 btn-icon"
              @click="confirmDelete(data)"
            >
              <feather-icon
                icon="TrashIcon"
              />
            </b-button>
            <!-- <b-link @click="confirmDelete(data)">
              <b-badge variant="danger">
                <feather-icon icon="TrashIcon" />
              </b-badge>
            </b-link> -->
          </span>
        </template>

        <!-- Column: Invoice Status -->
        <template
          v-for="(_, slot) of $scopedSlots"
          v-slot:[slot]="scope"
        >
          <slot
            :name="slot"
            v-bind="scope"
          />
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            v-if="info_entries != false"
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ currentPage }} to {{ pageSize }} of {{ recordsTotal }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="isPagination"
              v-model="currentPage"
              :total-rows="recordsTotal"
              :per-page="pageSize"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-overlay>
    <b-modal
      id="modal-center"
      ref="my-modal-form"
      centered
      :title="(cek_modal == 'edit') ? 'Edit '+title_modal : 'Tambah '+title_modal "
      hide-footer
    >
      <div>
        <baseform
          :title_form="title_form"
          :cek_modal="cek_modal"
          :fields="fields"
          :posturl="dataurl"
          :dataitem="dataitem"
          :ismodal="true"
          @onsubmite="submitModal"
        /></div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BTable, BRow, BCol, BFormGroup, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BLink,BBadge,BOverlay,BModal,BCard
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import baseform from '@/views/base/BaseFormV2.vue'
import { useEventListener } from '@vueuse/core'

export default {
  components: {
    BTable,
    BCard,
    BCol,
    // BFormGroup,
    BPagination,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    BRow,
    // BLink,
    // BBadge,
    BOverlay,
    BModal,
    baseform,
    vSelect,
  },
  props: {
    isAdd: {
      type: Boolean,
      default: true,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
    isPagination:{
      type: Boolean,
      default: true
    },
    isEdit: {
      type: Boolean,
      default: true,
    },
    custom_action: {
      type: Boolean,
      default: true,
    },
    isDelete: {
      type: Boolean,
      default: true,
    },
    info_entries: {
      type: Boolean,
      default: true,
    },
    tombol_hide: {
      type: Boolean,
      default: false,
    },
    no_margin: {
      type: Boolean,
      default: false,
    },
    search_hide: {
      type: Boolean,
      default: false,
    },
    page_size_hide: {
      type: Boolean,
      default: false
    },
    isView: {
      type: Boolean,
      default: true,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    custom_filter: {
      type: Boolean,
      default: false,
    },
    statusTable: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    title_modal: {
      type: String,
      default: '',
    },
    baseroute: {
      type: String,
      default: '',
    },
    dataurl: {
      type: String,
      default: '',
    },
    fields: {
      type: Array,
      default() {
        return []
      },
    },
    dataParams: {
      type: Array,
      default() {
        return []
      },
    },
    fieldsform: {
      type: Array,
      default() {
        return []
      },
    },
    // filter: {
    //   type: Object,
    //   default: function () {
    //     return {}
    //   }
    // },
    filter: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      title_form:'',
      currentPage: 1,
      sortBy: '',
      cek_modal:null,
      sortDirection: true,
      recordsTotal: 0,
      perPage: ['20','40','60'],
      pageSize: 20,
      search: '',
      items:[],
      loading:false,
      dataitem:{},
      fieldsTable:[],
    }
  },
  watch: {
    search() {
      this.fetchData()
    },
    currentPage() {
      this.fetchData()
    },
    sortDirection() {
      this.fetchData()
    },
    pageSize() {
      this.fetchData()
    }
  },
  beforeMount(){
    let newobj=JSON.parse(JSON.stringify(this.fields))
    for (let index = 0; index < newobj.length; index++) {
      let row = newobj[index]
      if(row.type == 'select-ajax'){
        row.key = row.key+'.name'
      }
      this.fieldsTable.push(row) 
    }
    if(this.custom_action == true){
      this.fieldsTable.push('action')
    }


  },
  mounted() {
    this.fetchData()
  },

  methods: {
    fetchData() {
      this.loading =true
      if(this.statusTable != true){
        // console.log(this.dataParams.data)
        this.recordsTotal = this.dataParams.recordsFiltered
        this.items = this.dataParams.data
        this.loading =false
      }else{

        var params ={...this.filter}
      
      // set paging
      params["start"] = (this.currentPage-1) * this.pageSize 
      params["length"] = this.pageSize 
      
      // set sort
      if(this.sortBy == '')
        params["sort[created_at]"] = "desc" 
      else
        params["sort["+this.sortBy+"]"] = this.sortDirection ? 'asc' :'desc'

      // set search
      if(this.search != '')
        params["search"] = this.search

      this.$http.get(this.dataurl,{params:params})
        .then(res  => {
          // this.pageLength = res.data.data.length
          this.recordsTotal = res.data.recordsFiltered
          this.items = res.data.data
          this.$emit('showData', this.items)
        })
        .catch(()=>{
          this.$bvToast.toast('Something wrong', {
            title: 'Fail',
            solid: true,
            variant:'warning'
          })
        })
        .finally(() => this.loading = false) 

      }
    },
    submitModal(val) {
      if(val) this.fetchData()
      this.$refs['my-modal-form'].hide()
    },
    addForm(){
      this.title_form='Tambah'
      if(this.isModal){
      this.cek_modal = "tambah"
      this.$refs['my-modal-form'].show()
      this.dataitem ={}
      }else{
      this.$router.push({ name: this.baseroute+'-create' })
      }
    },
    detail(data){
      this.title_form='Detail'
      this.$router.push({ name: this.baseroute+'-detail',params : { id: data.item.id} })
    },
    editForm(data){
      this.title_form='Edit'
      if(this.isModal){
        this.dataitem = data.item
        this.cek_modal = "edit"
        this.$refs['my-modal-form'].show()
      }else{
        this.$router.push({ name: this.baseroute+'-edit',params : { id: data.item.id} })
      }
    },
    viewForm(data){
      this.$router.push({ name: this.baseroute+'-view',params : { id: data.item.id} })
    },
    confirmDelete(data){
      const {id} = data.item
      this.$bvModal
        .msgBoxConfirm('Are you sure delete '+Object.values(data.item)[1]+' ?', {
          title: 'Please Confirm',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
          okVariant:'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
        })
        .then(confirm => {
          if(confirm){
            this.$http.delete(this.dataurl+'/'+id)
              .then(() => {
                this.fetchData()

                  this.items = data.data
                  this.recordsTotal = data.recordsTotal
                  this.loading =false
  
            })
          }
        })
    }
    // onFiltered() {
    //   // console.log(filteredItems)
    //   // Trigger pagination to update the number of buttons/pages due to filtering
    //   // this.totalRows = filteredItems.length
    //   // this.currentPage = 1
    //   this.fetchData()
    // },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
